const UserList = () => {

    return (
        <div>
            User list
        </div>
    );

}

export default UserList;