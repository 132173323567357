const DB_NAME = 'UserDB';
const DB_VERSION = 1;
const STORE_NAME = 'locations';

// Mở hoặc tạo cơ sở dữ liệu
export const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
      }
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      resolve(db);
    };

    request.onerror = (event) => {
      reject(`IndexedDB error: ${event.target.errorCode}`);
    };
  });
};

export const getLocationFromNavigator = () => {
  return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
              function(position) {
                  const latitude = position.coords.latitude;
                  const longitude = position.coords.longitude;

                  locationData.latitude = latitude;
                  locationData.longitude = longitude;

                  // Save the location to IndexedDB
                  addLocation(latitude, longitude);

                  resolve(true);
              },
              function(error) {
                  let message = '';
                  switch (error.code) {
                      case error.PERMISSION_DENIED:
                          message = "Permission denied. Please allow location access.";
                          break;
                      case error.POSITION_UNAVAILABLE:
                          message = "Location information is unavailable.";
                          break;
                      case error.TIMEOUT:
                          message = "The request to get user location timed out.";
                          break;
                      default:
                          message = "An unknown error occurred.";
                  }
                  console.log(message);
                  reject(new Error(message));
              }
          );
      } else {
          console.log("Geolocation is not supported by this browser.");
          reject(new Error("Geolocation not supported"));
      }
  });
}
// Thêm vị trí vào IndexedDB
export const addLocation = (location) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await openDB();
      const transaction = db.transaction([STORE_NAME], 'readwrite');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.add(location);

      request.onsuccess = () => {
        resolve(true);
      };

      request.onerror = (event) => {
        reject(`Add location error: ${event.target.errorCode}`);
      };
    } catch (error) {
      reject(error);
    }
  });
};

// Kiểm tra xem có vị trí nào trong IndexedDB không
export const hasLocation = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await openDB();
      const transaction = db.transaction([STORE_NAME], 'readonly');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.count();

      request.onsuccess = () => {
        resolve(request.result > 0);
      };

      request.onerror = (event) => {
        reject(`Count error: ${event.target.errorCode}`);
      };
    } catch (error) {
      reject(error);
    }
  });
};

// Lấy vị trí đầu tiên (nếu có)
export const getFirstLocation = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await openDB();
      const transaction = db.transaction([STORE_NAME], 'readonly');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.openCursor();

      request.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          resolve(cursor.value);
        } else {
          resolve(null);
        }
      };

      request.onerror = (event) => {
        reject(`Cursor error: ${event.target.errorCode}`);
      };
    } catch (error) {
      reject(error);
    }
  });
};

// Xóa tất cả vị trí
export const clearLocations = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await openDB();
      const transaction = db.transaction([STORE_NAME], 'readwrite');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.clear();

      request.onsuccess = () => {
        resolve(true);
      };

      request.onerror = (event) => {
        reject(`Clear error: ${event.target.errorCode}`);
      };
    } catch (error) {
      reject(error);
    }
  });
};
