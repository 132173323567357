import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import UserModelLogin from "../../app/components/UserModel/UserModelLogin";
import * as Icon from 'react-feather';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { getCookie } from '../../utils/helper';
import ApiService from '../../services/ApiService';
import WebSocketContext from '../../WebSocketContext';

const ChatWindow = () => {
  const { id } = useParams();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [friend, setFriend] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const messagesEndRef = useRef(null);
  // thời gian không hoạt động trên màn hình
  const inactivityTimeoutRef = useRef(null);
  const inactivityTimeLimit = 10 * 60 * 1000 ; // 10 phút
  const webSocket = useContext(WebSocketContext);
  const navigate = useNavigate();
  const token = getCookie('fast_access_token');
  const [hasMore, setHasMore] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [lastMessageId, setLastMessageId] = useState(null);
  const [readMessages, setReadMessages] = useState(new Set());

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userInfo = await ApiService.getUserToken();
        if (userInfo) {
          setCurrentUser(userInfo?.data?.data);
        } else {
          setShowLoginModal(true);
        }
      } catch (error) {
        console.error('Lỗi khi lấy thông tin người dùng:', error);
        alert('Đã xảy ra lỗi. Vui lòng đăng nhập lại.');
        navigate(`/account/login?callback=${encodeURIComponent(window.location.pathname)}`);
      }
    };
  
    if (token) {
      fetchUserInfo();
    } else {
      setShowLoginModal(true);
    }
  }, [token, navigate]);


  const handleSendMessage = async () => {
    if (inputValue.trim() && webSocket && webSocket.socket?.readyState === WebSocket.OPEN) {
      const temp_id = nanoid(); // Sử dụng nanoid cho temp_id
      const message = {
        id: temp_id, // Sử dụng temp_id trước khi nhận message_id từ server
        sender_id: currentUser?.user_id,
        receiver_id: id,
        content: inputValue.trim(),
        timestamp: new Date().toISOString(),
        status: 'sending', // Trạng thái ban đầu
        temp_id, // Lưu temp_id để cập nhật sau
      };
      try {
        // Gửi tin nhắn qua WebSocket
        webSocket.sendMessage(id, message.content, temp_id);
  
        // Cập nhật tin nhắn trong giao diện
        setMessages((prevMessages) => [...prevMessages, message]);
        setInputValue('');  // Xóa input sau khi gửi tin nhắn
      } catch (error) { 
        console.log('Error sending message:', error);
      }
    } 
  };

  
  useEffect(() => {
    if (!currentUser || !webSocket) return;

    const handleMessage = (data) => {

      console.log('data handleMessage', data);

      if (data.action === 'load_history_response') {
          const loadedMessages = data.messages.map(msg => ({
              ...msg,
              status: msg.is_read ? 'read' : (msg.is_delivered ? 'delivered' : 'sent'),
          }));
          if (loadedMessages.length > 0) {
              setMessages((prevMessages) => [...loadedMessages, ...prevMessages]);
              setLastMessageId(loadedMessages[0].id);
              if (loadedMessages.length < 20) {
                  setHasMore(false);
              }
          } else {
              setHasMore(false);
          }
      } else if (data.action === 'new_message') {
          const message = data.data;
          const status = message.is_read ? 'read' : (message.is_delivered ? 'delivered' : 'sent');
          setMessages((prevMessages) => [...prevMessages, { ...message, status }]);
       
      } else if (data.action === 'message_sent') {
        // đã gửi
          const { message_id, temp_id } = data;
          setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                  msg.temp_id === temp_id ? { ...msg, id: message_id, status: 'sent' } : msg
              )
          );
      } else if (data.action === 'message_delivered') {
        // đã nhận
          const { message_id } = data;
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
                msg.id === message_id ? { ...msg, status: 'delivered' } : msg
            )
          );
      } else if (data.action === 'message_read') {
        // đã đọc
          const { message_id } = data;
          setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                  msg.id === message_id ? { ...msg, status: 'read' } : msg
              )
          );
      }
    };

    webSocket.onMessage(handleMessage);

    // Tải 20 tin nhắn mới nhất khi kết nối thành công
    if (webSocket.socket && webSocket.socket.readyState === WebSocket.OPEN) {
      webSocket.loadHistory(id, 20, null);
    } else {
      webSocket.socket.addEventListener('open', () => {
          webSocket.loadHistory(id, 20, null);
      });
    }

    return () => {
        webSocket.offMessage(handleMessage);
    };
  }, [currentUser, webSocket, id]);

  useEffect(() => {
    const getUserFriend = async () => {
      try {
        const response = await ApiService.getUserById(id);
        if (response && response.data && response.data.data) {
          setFriend(response.data.data); // Cập nhật trạng thái friend
        } else {
          console.error('Không thể lấy thông tin người bạn từ API.');
        }
      } catch(err) {
        console.error('Lỗi khi lấy thông tin người bạn:', err);
      }
    };
    if (id) {
      getUserFriend();
    }
  }, [id]);


const markMessagesAsRead = () => {
  if (webSocket && webSocket.socket?.readyState === WebSocket.OPEN) {
      // Tìm tất cả các tin nhắn đã nhận nhưng chưa đọc
      const deliveredMessages = messages.filter(msg => 
          msg.status === 'delivered' && !readMessages.has(msg.id)
      );

    deliveredMessages.forEach(msg => {
        webSocket.sendMessageRead(msg.id);
        console.log('Sent message_read for message_id:', msg.id);
        setReadMessages(prev => new Set(prev).add(msg.id));
        // Cập nhật trạng thái tin nhắn thành 'read' trên giao diện
        setMessages(prevMessages => prevMessages.map(m => 
            m.id === msg.id ? { ...m, status: 'read' } : m
        ));
    });
  }
};
useEffect(() => {
  markMessagesAsRead();
}, [messages, currentUser, webSocket]);


  // Cuộn xuống khi có tin nhắn mới
useEffect(() => {
  messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
}, [messages]);

  const scrollContainerRef = useRef(null);

useEffect(() => {
    const handleScroll = () => {
        if (scrollContainerRef.current) {
            const { scrollTop } = scrollContainerRef.current;
            if (scrollTop === 0 && hasMore && !loadingHistory) {
                loadMoreHistory();
            }
        }
    };

    const container = scrollContainerRef.current;
    if (container) {
        container.addEventListener('scroll', handleScroll);
    }

    return () => {
        if (container) {
            container.removeEventListener('scroll', handleScroll);
        }
    };
}, [hasMore, loadingHistory, lastMessageId]);

const loadMoreHistory = () => {
  if (webSocket && lastMessageId) {
    setLoadingHistory(true);
    
    const container = scrollContainerRef.current;
    const previousScrollHeight = container.scrollHeight;

    webSocket.loadHistory(id, 20, lastMessageId);

    // Sau một khoảng delay để đảm bảo tin nhắn đã được tải
    setTimeout(() => {
      const newScrollHeight = container.scrollHeight;
      container.scrollTop = newScrollHeight - previousScrollHeight;
      setLoadingHistory(false);
    }, 500); // Điều chỉnh thời gian delay nếu cần
  }
};

console.log('friend:', friend);
console.log('messages:', messages);
console.log('currentUser:', currentUser);

function groupMessages(messages) {
  const groups = [];
  let currentGroup = null;

  messages.forEach((message, index) => {
    if (!currentGroup || currentGroup.sender_id !== message.sender_id) {
      if (currentGroup) {
        const lastMessage = currentGroup.messages[currentGroup.messages.length - 1];
        lastMessage.showTime = true;
        groups.push(currentGroup);
      }
      currentGroup = {
        sender_id: message.sender_id,
        messages: [message],
      };
    } else {
      currentGroup.messages.push(message);
    }

    if (index === messages.length - 1) {
      currentGroup.messages[currentGroup.messages.length - 1].showTime = true;
      groups.push(currentGroup);
    }
  });
  return groups;
}


  useEffect(() => {
    const resetInactivityTimeout = () => {
      // Nếu WebSocket đã bị ngắt kết nối, kết nối lại (nếu muốn)
      if (webSocket && (!webSocket.socket || webSocket.socket.readyState === WebSocket.CLOSED)) {
        webSocket.connect();
      }

      // Xóa timeout hiện tại
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      // Thiết lập timeout mới
      inactivityTimeoutRef.current = setTimeout(() => {
        if (webSocket && webSocket.socket) {
          webSocket.disconnect();
          alert('Bạn đã không hoạt động trong 10 phút, kết nối đã bị ngắt.');
        }
      }, inactivityTimeLimit);
    };

    // Danh sách các sự kiện để lắng nghe
    const events = ['mousemove', 'keydown', 'wheel', 'touchstart'];

    // Thêm event listener cho mỗi sự kiện
    events.forEach((event) => {
      window.addEventListener(event, resetInactivityTimeout);
    });

    // Đặt timeout lần đầu tiên khi component được mount
    resetInactivityTimeout();

    // Dọn dẹp khi component bị unmount
    return () => {
      // Xóa tất cả các event listener
      events.forEach((event) => {
        window.removeEventListener(event, resetInactivityTimeout);
      });
      // Xóa timeout
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
    };
  }, [webSocket]);


console.log('friend:', friend);
console.log('messages:', messages);
console.log('currentUser:', currentUser);

  return (
    <div className="flex flex-col h-screen justify-between">
      <div className="bg-primary flex gap-2 items-center p-4 border-b text-white">
        <Link to={`/user/${id}`}><Icon.ChevronLeft /></Link>
        <img src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=1528&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Avatar" className="object-cover w-10 h-10 rounded-full" />
        <div className="ml-3">
          <h2 className="text-lg font-semibold">{friend?.fullname}</h2>
          <p className="text-sm text-gray-300">Đang hoạt động</p>
        </div>
      </div>

      {showLoginModal && <UserModelLogin callbackUrl={window.location.pathname} />}

      <div className="flex-1 p-4 overflow-y-auto bg-white" style={{ maxHeight: 'calc(100vh - 144px)' }} ref={scrollContainerRef}>
        {messages.length > 0 ? (
            groupMessages(messages).map((group, groupIndex) => (
                <div key={groupIndex} className="mb-4">
                    {group.messages.map((message, index) => {
                      const isLastMessage = index === group.messages.length - 1;
                      const isCurrentUser = message.sender_id === currentUser?.user_id;
                      return (
                        <div
                          key={index}
                          className={`flex mb-4 ${
                            isCurrentUser ? 'justify-end items-end' : 'justify-start'
                          }`}
                        >
                          <div
                            className={`p-2 rounded-lg ${
                              isCurrentUser ? 'mr-2 bg-primary text-white' : 'ml-2 bg-gray-200 text-gray-800'
                            }`}
                          >
                            <p>{message.content}</p>
                            {message.showTime && (
                              <span className={`text-xs ${isCurrentUser ? 'text-gray-200' : 'text-gray-500'}`}>
                                {moment(message.timestamp).format('HH:mm')}
                              </span>
                            )}  
                          </div>
                        </div>
                      );
                    })}
                </div>
            ))
        ) : (
            <p className="text-center text-gray-500">No messages yet...</p>
        )}

        <div ref={messagesEndRef} />
      </div>

      <footer className="p-3 bg-white border-t border-gray-200 flex items-center space-x-3">
        <input
          type="text"
          placeholder={`Nhập @, tin nhắn tới ${friend?.fullname}`}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleSendMessage(); 
          }}
          className="flex-1 bg-white focus:outline-none"
        />
        <button
          onClick={handleSendMessage}
        >
          <Icon.Send stroke="#1c64f2"/>
        </button>
      </footer>
    </div>
    );
}

export default ChatWindow;
