import React from "react";

const Register = () => {
    return (
    <p>
        đăng ký
    </p>
    )
}

export default Register;