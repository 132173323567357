    import React, { useState, useEffect } from 'react'; 
    import ApiService from '../../../../services/ApiService';
    import { useParams } from 'react-router-dom';
    import { useForm } from 'react-hook-form';
    import InputField   from '../../../components/FormField/InputFìeld';

    const UserProfile = () => {
        const { id } = useParams();
        const [user, setUser] = useState(null);
        const [gender, setGender] = useState('');

        const genderLabels = {
            '1': 'Nam', 
            '0': 'Nữ',  
        };
        const { register, handleSubmit, formState: { errors }, reset } = useForm();

        useEffect(() => {
            const fetchUser = async () => { 
            try {
                const response = await ApiService.getUserById(id);
                if (!response.status) {
                    throw new Error(`Error: ${response.status} ${response.statusText}`);
                }
                const userData = response?.data?.data;
                setUser(userData); 
                setGender(userData.gender != null ? userData.gender.toString() : '');
            } catch (err) {
                console.error('error user fetch', err)
            }
            };
            fetchUser();
        }, [id]);

        const handleGenderChange = (event) => {
            setGender(event.target.value);
        };

        useEffect(() => {
            if (user) {
                const formattedBirthday = user?.birthday ? user?.birthday.split('T')[0] : '';
        
                reset({
                    fullname: user?.fullname,
                    birthday: formattedBirthday,
                    phone: user?.phone,
                    email: user?.email,
                    height: user?.personal?.height,
                    school: user?.personal?.school,
                    company: user?.personal?.company,
                    live: user?.personal?.live,
                    about_me: user?.personal?.about_me,
                });
        
                setGender(user?.gender != null ? user?.gender.toString() : '');
            }
        }, [user, reset]);
        

        const handleChangeProfile = async (data) =>{
            try {
                const updateData = {
                    fullname: data.fullname,
                    birthday: data.birthday,
                    phone: data.phone,
                    email: data.email,
                    gender: gender,
                    height: data.height,
                    school: data.school,
                    company: data.company,
                    live: data.live,
                    about_me: data.about_me
                };

                const updateProfile = await ApiService.updateProfileUser({id, data: updateData});
                alert('Profile update success');
                console.log('Profile updated successfully', updateProfile); 
            } catch (err) {
                console.error('Error updating profile', err);
            }
        };

        const onSubmit = (data) => {
            handleChangeProfile(data);
        };

        const handleChangeInput = (e) => {
            console.log(e);
        }

        console.log(user?.personal?.live);

        return (
            <div className="mx-auto p-4">
                <h1 className="text-2xl font-semibold text-center mb-6">Chỉnh Sửa Hồ Sơ</h1>
                
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4"> 
                    <div className="flex justify-center mb-4">
                        <div className="relative">
                            <img src="https://via.placeholder.com/100" alt="Avatar" className="w-24 h-24 rounded-full object-cover" />
                            <button className="absolute bottom-0 right-0 bg-primary text-white rounded-full p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536M9.414 10.586L4 16v4h4l5.586-5.586a2 2 0 012.828 0l2.828 2.828a2 2 0 010 2.828l-1.414 1.414a2 2 0 01-2.828 0L9.414 16.828a2 2 0 010-2.828L12 10.586z" />
                            </svg>
                            </button>
                        </div>
                    </div>

                    <InputField 
                        id="fullname"
                        label="Tên"
                        register={register}
                        errors={errors}
                        defaultValue={user?.fullname}
                        placeholder="Nhập tên của bạn"
                        required="Tên là bắt buộc"
                    />
                    <InputField
                        id="birthday"
                        label="Ngày sinh"
                        type="date"
                        register={register}
                        errors={errors}
                        defaultValue={user?.birthday?.split('T')[0]}
                        required="Ngày sinh là bắt buộc"
                    />
                    <InputField
                        id="phone"
                        label="Số điện thoại"
                        type="tel"
                        register={register}
                        errors={errors}
                        defaultValue={user?.phone}
                        required="Số điện thoại là bắt buộc"
                    />
                    <InputField
                        id="email"
                        label="Email"
                        type="email"
                        register={register}
                        errors={errors}
                        defaultValue={user?.email}
                        required="Email là bắt buộc"
                    />
    
                    <div>
                        <div className="item-title flex items-center justify-between cursor-pointer mb-2">
                            <div className="label">
                                <h4 className="font-medium">Giới tính</h4>
                                <p className="amount text-xs text-gray-400">{genderLabels[gender]}</p>
                            </div>
                        </div>
                        <div className="item-content mt-3">
                            <div className="flex">
                                <div className="flex items-center me-4">
                                    <input
                                        type="radio"
                                        id="men"
                                        name="gender"
                                        value="1"
                                        checked={gender === '1'}
                                        onChange={handleGenderChange}
                                        className="radio-input w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                    />
                                    <label htmlFor="men" className="ms-2 text-sm font-medium text-gray-900">
                                        Nam
                                    </label>
                                </div>
                                <div className="flex items-center me-4">
                                    <input
                                        type="radio"
                                        id="women"
                                        name="gender"
                                        value="0"
                                        checked={gender === '0'}
                                        onChange={handleGenderChange}
                                        className="radio-input w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                    />
                                    <label htmlFor="women" className="ms-2 text-sm font-medium text-gray-900">
                                        Nữ
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="height" className="block text-gray-700 mb-2">Chiều cao</label>
                        <div className="w-full inline-flex rounded-lg border border-gray-200 bg-white p-3 text-sm font-normal text-gray-900 outline-none pl-2 pr-14">
                            <input
                            id="height"
                            name="height"
                            type="number"
                            onChange={handleChangeInput}
                            defaultValue={user?.personal?.height}
                            {...register('height', { required: false })}
                            placeholder="Nhập chiều cao của bạn"
                            className="w-full"
                            />
                            <span>cm</span>
                        </div>
                    </div>
                    <InputField
                        id="school"
                        label="Trường học"
                        type="text"
                        register={register}
                        defaultValue={user?.personal?.school}
                    />
                    <InputField
                        id="jobs"
                        label="Nghề nghiệp"
                        type="text"
                        register={register}
                        defaultValue={user?.personal?.jobs}
                    />
                    <InputField
                        id="marital_status"
                        label="Tình trạng hôn nhân"
                        type="text"
                        register={register}
                        defaultValue={user?.personal?.marital_status}
                    />
                    <InputField
                        id="horoscope"
                        label="Tử vi"
                        type="text"
                        register={register}
                        defaultValue={user?.personal?.horoscope}
                    />
                    <InputField
                        id="sexuality"
                        label="Xu hướng tính dục"
                        type="text"
                        register={register}
                        defaultValue={user?.personal?.sexuality}
                    />
                    <InputField
                        id="character"
                        label="Tính cách"
                        type="text"
                        register={register}
                        defaultValue={user?.personal?.character}
                    />
                    <InputField
                        id="religion"
                        label="Tôn giáo"
                        type="text"
                        register={register}
                        defaultValue={user?.personal?.religion}
                    />
                    <InputField
                        id="find_relationship"
                        label="Tìm kiếm mối quan hệ"
                        type="text"
                        register={register}
                        defaultValue={user?.personal?.find_relationship}
                    />
                    <InputField
                        id="nation"
                        label="Dân tộc"
                        type="text"
                        register={register}
                        defaultValue={user?.personal?.nation}
                    />
                    <InputField
                        id="company"
                        label="Công ty"
                        type="text"
                        register={register}
                        defaultValue={user?.personal?.company}
                    />
                    <InputField
                        id="free_time"
                        label="Thời gian rảnh"
                        type="text"
                        register={register}
                        defaultValue={user?.personal?.free_time}
                    />
                    <InputField
                        id="live"
                        label="Sống ở"
                        type="text"
                        register={register}
                        defaultValue={user?.personal?.live}
                    />
                  <div>
                        <label htmlFor="about_me" className="block text-gray-700 mb-2">Giới thiệu bản thân</label>
                        <textarea 
                            rows="4" 
                            id="about_me"
                            name="about_me"
                            defaultValue={user?.personal?.about_me}
                            {...register('about_me', { required: false })}
                            placeholder="Viết một vài thông tin về bạn,...." 
                            className="w-full block rounded-lg border border-gray-200 bg-white p-3 text-sm font-normal text-gray-900 outline-none"></textarea>
                    </div>
                    <div>
                        <label className="block text-gray-700 mb-2">Ảnh thêm</label>
                        <div className="flex space-x-2">
                        <div className="relative">
                            <img src="https://via.placeholder.com/80" alt="Photo 1" className="w-20 h-20 rounded-md object-cover" />
                            <button className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                            </button>
                        </div>
                        <div className="flex items-center justify-center w-20 h-20 border-2 border-dashed border-gray-300 rounded-md cursor-pointer hover:bg-gray-200">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                            </svg>
                        </div>
                        </div>
                    </div>

                    <div>
                        <button type="submit" className="w-full bg-primary text-white p-2 rounded-md hover:bg-blue-600 transition duration-200">Lưu Thay Đổi</button>
                    </div>
                </form>
            </div>
        )
    }

    export default UserProfile;
