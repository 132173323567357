import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import ApiService from "../../../services/ApiService";
import { getFirstLocation, clearLocations } from "../../../services/indexedDB"; 
import { getCookie } from '../../../utils/helper';

const Settings = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [gender, setGender] = useState('');
    const genderLabels = {
        '': 'Tất cả',
        '1': 'Nam',
        '0': 'Nữ',
    };
    const [showGenderOptions, setShowGenderOptions] = useState(false);
    const [location, setLocation] = useState('');
    // State quản lý tuổi
    const [ageRange, setAgeRange] = useState({ min: 15, max: 60 });
    const [showAgeSlider, setShowAgeSlider] = useState(false);
    const [error, setError] = useState(null);
    const [activeHandle, setActiveHandle] = useState(null); // 'min' hoặc 'max'
    const token = getCookie('fast_access_token');
    const navigate = useNavigate();
    useEffect(() => {
        const fetchUserInfo = async () => {
          if(token){
            const userInfo = await ApiService.getUserToken();
            if (userInfo) {
              setCurrentUser(userInfo?.data?.data);
            } else {
              alert('Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập lại.');
              navigate(`/account/login?callback=${encodeURIComponent(callbackUrl)}`);
            }
        };
        }
        fetchUserInfo();
      }, [token, navigate]);



    // Ref để lấy kích thước của thanh trượt
    const sliderRef = useRef(null);

    // Hàm toggle hiển thị phần chọn giới tính
    const toggleGenderOptions = () => {
        setShowGenderOptions(!showGenderOptions);
    };

    // Hàm toggle hiển thị phần chọn tuổi
    const toggleAgeSlider = () => {
        setShowAgeSlider(!showAgeSlider);
    };
// Hàm xử lý khi chọn giới tính
    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };

    useEffect(() => {
        const fetchLocationFromDB = async () => {
            try {
                const locationData = await getFirstLocation();
                if (locationData) {
                    const locationString = `${locationData.latitude},${locationData.longitude}`;
                    setLocation(locationString);
                } else {
                    console.warn('Không có vị trí nào trong IndexedDB');
                }
            } catch (error) {
                console.error('Lỗi khi lấy vị trí từ IndexedDB:', error);
            }
        };

        fetchLocationFromDB();
    }, []);

    const userId = currentUser?.data?.user_id; 
    // Hàm xử lý khi submit form
    const handleSubmit = async (event) => {
        event.preventDefault();
        // Chuẩn bị tham số tìm kiếm
        const searchParams = {
            user_id: userId,
            gender: gender || undefined,
            minAge: ageRange.min,
            maxAge: ageRange.max,
            location: location || undefined,
        };
        try {
            // Gọi API getuser với các tham số
            const response = await ApiService.getUsers(searchParams);
            if (response.status === 200) {

                navigate('/', { state: { users: response?.data } });
            } else {
                console.error('Lỗi khi tìm kiếm người dùng:', response.statusText);
                setError('Đã xảy ra lỗi khi tìm kiếm người dùng.');
            }
        } catch (err) {
            console.error('Lỗi khi gọi API:', err);
            setError('Đã xảy ra lỗi khi kết nối tới máy chủ.');
        }
    };

    // Hàm xử lý xoá vị trí
    const handleDeleteLocation = async () => {
        try {
            await clearLocations();
            alert('Đã xoá vị trí.');
            navigate('/'); // Điều hướng đến trang yêu cầu quyền truy cập vị trí
        } catch (err) {
            console.error('Lỗi khi xoá vị trí:', err);
            alert('Đã xảy ra lỗi khi xoá vị trí.');
        }
    };

    // Hàm xử lý khi nhấp vào thanh trượt tuổi
    const handleSliderClick = (event) => {
        if (!sliderRef.current) return;

        const rect = sliderRef.current.getBoundingClientRect();
        const clickX = event.clientX - rect.left; // Vị trí X trong thanh trượt
        const percentage = clickX / rect.width;
        let age = Math.round(percentage * (100 - 1) + 1); // Tính tuổi từ 1 đến 100

        // Đảm bảo tuổi nằm trong khoảng 1 - 100
        age = Math.max(1, Math.min(age, 100));

        // Tính khoảng cách đến min và max hiện tại
        const distanceToMin = Math.abs(age - ageRange.min);
        const distanceToMax = Math.abs(age - ageRange.max);

        if (distanceToMin < distanceToMax) {
            // Nếu gần min hơn, cập nhật min
            const newMin = Math.min(age, ageRange.max);
            setAgeRange({ ...ageRange, min: newMin });
        } else {
            // Nếu gần max hơn, cập nhật max
            const newMax = Math.max(age, ageRange.min);
            setAgeRange({ ...ageRange, max: newMax });
        }
    };

    // Hàm xử lý khi bắt đầu kéo
    const handleDragStart = (handle) => (event) => {
        event.preventDefault();
        setActiveHandle(handle);
    };

    // Hàm xử lý khi kéo
    const handleDrag = (event) => {
        if (!activeHandle || !sliderRef.current) return;

        const rect = sliderRef.current.getBoundingClientRect();
        let clientX;

        if (event.type.startsWith('mouse')) {
            clientX = event.clientX;
        } else if (event.type.startsWith('touch')) {
            clientX = event.touches[0].clientX;
        }

        const clickX = clientX - rect.left;
        const percentage = clickX / rect.width;
        let age = Math.round(percentage * (100 - 1) + 1); // Tính tuổi từ 1 đến 100

        // Đảm bảo tuổi nằm trong khoảng 1 - 100
        age = Math.max(1, Math.min(age, 100));

        if (activeHandle === 'min') {
            const newMin = Math.min(age, ageRange.max);
            setAgeRange({ ...ageRange, min: newMin });
        } else if (activeHandle === 'max') {
            const newMax = Math.max(age, ageRange.min);
            setAgeRange({ ...ageRange, max: newMax });
        }
    };

    // Hàm xử lý khi kết thúc kéo
    const handleDragEnd = () => {
        setActiveHandle(null);
    };

    useEffect(() => {
        if (activeHandle) {
            document.addEventListener('mousemove', handleDrag);
            document.addEventListener('mouseup', handleDragEnd);
            document.addEventListener('touchmove', handleDrag);
            document.addEventListener('touchend', handleDragEnd);
        } else {
            document.removeEventListener('mousemove', handleDrag);
            document.removeEventListener('mouseup', handleDragEnd);
            document.removeEventListener('touchmove', handleDrag);
            document.removeEventListener('touchend', handleDragEnd);
        }

        // Cleanup khi component unmount
        return () => {
            document.removeEventListener('mousemove', handleDrag);
            document.removeEventListener('mouseup', handleDragEnd);
            document.removeEventListener('touchmove', handleDrag);
            document.removeEventListener('touchend', handleDragEnd);
        };
    }, [activeHandle]);

    return (
        <div className="min-h-screen flex flex-col">
            {/* Header */}
            <header className="header sticky top-0 bg-primary">
                <div className="container">
                    <div className="py-3 relative">
                        {/* Nút quay lại */}
                        <a
                            href="#"
                            className="absolute cursor-pointer top-3 left-0"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(-1);
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5" className="stroke-white size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                            </svg>
                        </a>
                        {/* Tiêu đề */}
                        <div className="text-center text-white">Tìm quanh đây</div>
                    </div>
                </div>
            </header>

            {/* Main Content */}
            <div className="page-main relative">
                <form onSubmit={handleSubmit}>
                    <div className="block bg-white w-full pt-2 mb-2 searching-object">
                        <div className="container">
                            <div className="block-title uppercase font-medium text-sm">Đối tượng tìm kiếm</div>
                            <div className="block-content">
                                {/* Phần chọn Giới tính */}
                                <div className="item py-4 border-b border-b-gray-200">
                                    <div
                                        className="item-title flex items-center justify-between cursor-pointer"
                                        onClick={toggleGenderOptions}
                                    >
                                        <div className="label">
                                            <h4 className="font-medium">Giới tính</h4>
                                            <p className="amount text-xs text-gray-400">{genderLabels[gender]}</p>
                                        </div>
                                        <div className="icon transition-transform duration-300">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="size-6">
                                                <path d="M9.5 7L14.5 12L9.5 17" className="stroke-gray-400" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    {showGenderOptions && (
                                        <div className="item-content mt-3">
                                            <div className="flex">
                                                {/* Radio Button "Tất cả" */}
                                                <div className="flex items-center me-4">
                                                    <input
                                                        type="radio"
                                                        id="all"
                                                        name="gender"
                                                        value=""
                                                        checked={gender === ''}
                                                        onChange={handleGenderChange}
                                                        className="radio-input w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                                    />
                                                    <label htmlFor="all" className="ms-2 text-sm font-medium text-gray-900">
                                                        Tất cả
                                                    </label>
                                                </div>

                                                {/* Radio Button "Nam" */}
                                                <div className="flex items-center me-4">
                                                    <input
                                                        type="radio"
                                                        id="men"
                                                        name="gender"
                                                        value="1"
                                                        checked={gender === '1'}
                                                        onChange={handleGenderChange}
                                                        className="radio-input w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                                    />
                                                    <label htmlFor="men" className="ms-2 text-sm font-medium text-gray-900">
                                                        Nam
                                                    </label>
                                                </div>

                                                {/* Radio Button "Nữ" */}
                                                <div className="flex items-center me-4">
                                                    <input
                                                        type="radio"
                                                        id="women"
                                                        name="gender"
                                                        value="0"
                                                        checked={gender === '0'}
                                                        onChange={handleGenderChange}
                                                        className="radio-input w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                                    />
                                                    <label htmlFor="women" className="ms-2 text-sm font-medium text-gray-900">
                                                        Nữ
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Phần chọn Tuổi */}
                                <div className="item py-4">
                                    <div
                                        className="item-title flex items-center justify-between cursor-pointer"
                                        onClick={toggleAgeSlider}
                                    >
                                        <div className="label">
                                            <h4 className="font-medium">Tuổi</h4>
                                            <input
                                                type="text"
                                                id="amount"
                                                name="age"
                                                className="text-xs text-gray-400"
                                                readOnly
                                                value={`${ageRange.min} - ${ageRange.max}`}
                                            />
                                        </div>
                                        <div className="icon transition-transform duration-300">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="w-6 h-6">
                                                <path d="M9.5 7L14.5 12L9.5 17" className="stroke-gray-400" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    {showAgeSlider && (
                                        <div className="item-content mt-4 px-4">
                                            {/* Thanh trượt tuổi */}
                                            <div
                                                id="slider-range"
                                                className="w-full relative h-8 bg-gray-200 rounded-full cursor-pointer"
                                                ref={sliderRef}
                                                onClick={handleSliderClick} // Đảm bảo hàm này đã được định nghĩa
                                            >
                                                {/* Thanh trượt chính */}
                                                <div
                                                    className="slider-track absolute top-1/2 transform -translate-y-1/2 w-full h-2 bg-blue-500 rounded-full"
                                                ></div>

                                                {/* Vùng chọn khoảng tuổi */}
                                                <div
                                                    className="slider-selection absolute top-1/2 transform -translate-y-1/2 h-2 bg-blue-700 rounded-full"
                                                    style={{
                                                        left: `${((ageRange.min - 1) / 99) * 100}%`,
                                                        width: `${((ageRange.max - ageRange.min) / 99) * 100}%`
                                                    }}
                                                ></div>

                                                {/* Thanh trượt cho Min */}
                                                <div
                                                    className="handle absolute top-1/2 transform -translate-y-1/2 w-6 h-6 bg-yellow-400 rounded-full cursor-pointer"
                                                    style={{
                                                        left: `${((ageRange.min - 1) / 99) * 100}%`,
                                                        transform: 'translate(-50%, -50%)'
                                                    }}
                                                    onMouseDown={handleDragStart('min')}
                                                    onTouchStart={handleDragStart('min')}
                                                ></div>

                                                {/* Thanh trượt cho Max */}
                                                <div
                                                    className="handle absolute top-1/2 transform -translate-y-1/2 w-6 h-6 bg-yellow-400 rounded-full cursor-pointer"
                                                    style={{
                                                        left: `${((ageRange.max - 1) / 99) * 100}%`,
                                                        transform: 'translate(-50%, -50%)'
                                                    }}
                                                    onMouseDown={handleDragStart('max')}
                                                    onTouchStart={handleDragStart('max')}
                                                ></div>
                                            </div>
                                            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="action-bottom">
                            <div className="flex gap-4 justify-center items-center mt-6">
                                <Link to="/" className="btn btn-secondary">
                                    Đóng
                                </Link>
                                <button type="submit" className="btn btn-primary">
                                    Tìm kiếm
                                </button>
                            </div>
                            <div className="flex gap-4 justify-center items-center mt-6">
                                <button
                                    type="button"
                                    id="delete_location"
                                    className="underline text-sm text-gray-400"
                                    onClick={handleDeleteLocation}
                                >
                                    Xoá vị trí để tránh làm phiền
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Settings;
