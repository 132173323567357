import { Outlet } from "react-router-dom";
import AuthSidebar from "./AuthSidebar";

const AuthLayout = () => {
  return (
    <div className="authorize">
        <div className="page-wrapper">
            <div className="flex flex-wrap">
                <AuthSidebar />
                <Outlet />
            </div>
        </div>
    </div>
  );
};

export default AuthLayout;
