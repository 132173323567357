import React from "react";
import Logo from "./../../../assets/image/logo.svg";
import IconSmart from "./../../../assets/image/icon_smart.svg";
const AuthSidebar = () => {
  return (
    <div className="w-full md:w-1/3">
      <div className="content bg-blue-800 h-auto md:h-full md:min-h-screen pt-12 px-5">
        <div className="logo mb-3">
            <img src={Logo} alt="Logo" />
        </div>
        <p className="welcome text-white font-semibold text-lg leading-[26px] mb-3">
          Welcome Back!
        </p>
        <div className="font-semibold text-2xl leading-8 text-white mb-3">
          Ready to Boost Your Campaigns?
        </div>
        <div className="greating leading-6 text-white mb-10">
          Log in to access your dashboard, track performance, and optimize your ads for maximum impact.
        </div>
        <div className="feature">
          <ul className="flex flex-col gap-4">
            <li className="flex items-center gap-3">
              <span className="icon">
                <img src={IconSmart} alt="Logo" />
              </span>
              <span className="text font-semibold text-lg leading-6 text-white">
                Advanced Analytics
              </span>
            </li>
            <li className="flex items-center gap-3">
              <span className="icon">
                <img src={IconSmart} alt="Logo" />
              </span>
              <span className="text font-semibold text-lg leading-6 text-white">
                Refine Your Targeting Strategy
              </span>
            </li>
            <li className="flex items-center gap-3">
              <span className="icon">
                <img src={IconSmart} alt="Logo" />
              </span>
              <span className="text font-semibold text-lg leading-6 text-white">
                Optimize Your Campaign
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AuthSidebar;
