import React from 'react';

const UserModelLogin = ({ callbackUrl, onClose }) => {

  const baseUrlAuth = process.env.REACT_APP_PORT_URL_SERVER + '/account/';

  return (
    <div onClick={onClose} className="overlay z-20 fixed top-0 right-0 left-64 b-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-2 w-4/5 md:w-[480px]" onClick={(e) => e.stopPropagation()}>
        <h2 className="text-lg font-bold mb-4">Oops, bạn chưa đăng nhập rồi!</h2>
        <p className="block mb-4">
          Vui lòng đăng nhập để trải nghiệm app.
        </p>
        <div className="flex justify-center gap-3 items-center mb-4">
          <a href={`${baseUrlAuth}/register?callback=${encodeURIComponent(callbackUrl)}`} className="btn btn-primary w-auto">
            Đăng ký
          </a>
          <a href={`${baseUrlAuth}/login?callback=${encodeURIComponent(callbackUrl)}`} className="btn btn-primary w-auto">
            Đăng nhập
          </a>
        </div>
      </div>
    </div>
  );
};

export default UserModelLogin;
