import React, { useState, useEffect } from 'react';
import ApiService from "../../../services/ApiService";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { getCookie } from '../../../utils/helper';

const Home = () => {

  const location = useLocation();
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const token = getCookie('fast_access_token');

  useEffect(() => {
    const fetchUserInfo = async () => {
      if(token){
        const userInfo = await ApiService.getUserToken();
        if (userInfo) {
          setCurrentUser(userInfo?.data?.data);
        } else {
          alert('Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập lại.');
          navigate(`/account/login?callback=${encodeURIComponent(callbackUrl)}`);
        }
    };
    }
    fetchUserInfo();
  }, [token, navigate]);

  const userId = currentUser?.data?.user_id;  

  useEffect(() => {
    if (location.state && location.state.users) {
      console.log(location?.state?.users?.data);
      setUsers(location?.state?.users?.data);
    } else {
      const fetchUsers = async () => {
        if (userId !== null) {
          const params = {
            user_id: userId
          }
          try {
            const response = await ApiService.getUsers(params);
            setUsers(response?.data?.data);
          } catch (error) {
            console.log('Error fetching users:', error);
          }
        } else {
          console.log('User ID is empty, not fetching users.');
        }
      };
      fetchUsers();
    }
  }, [userId, location.state]);
  console.log(users);

  const updateLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          navigate('/location');
        },
        (error) => {
          console.error('Error getting location:', error);
          alert('Không thể lấy vị trí hiện tại.');
        }
      );
    } else {
      alert('Trình duyệt của bạn không hỗ trợ Geolocation.');
    }
  };
  const gender = users?.search_info?.gender === null ? 'Tất cả, ' : ( parseInt(users?.search_info?.gender) === 1 ? 'Nam, ' : 'Nữ, ');
  console.log('CurrentUser:', currentUser);
  console.log('Token:', token);

  return (
    <>
      <div className="flex flex-col">
        <header className="header sticky top-0 bg-primary">
          <div className="container">
            <div className="py-3 relative">
              <div className="text-center text-white">Người dùng gần đây</div>
            </div>
          </div>
        </header>

        <Link to="settings" class="cursor-pointer shadow bg-white w-full py-2">
          <div class="container">
            <div class="flex items-center justify-between">
              <div class="toolbar-label flex items-center gap-3">
                <div class="title font-medium text-sm">Tìm bạn</div>
                <div class="list-attribute">
                  <ul class="flex gap-1 text-xs text-gray-400">
                    <li>{gender}</li>
                    <li>{`${users?.search_info?.ageFrom} - ${users?.search_info?.ageTo} tuổi`}</li>
                  </ul>
                </div>
              </div>
              <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  class="stroke-gray-400 size-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
              </div>
            </div>
          </div>
        </Link>

        <div className="page-main relative">
          <div className="list-user shadow-lg bg-white mt-3">
            {users?.users?.data && users?.users.data.length > 0 ? (
              users?.users.data.map((user) => (
                <div key={user.id} className="item border-b border-b-gray-200">
                  <div className="container">
                    <div className="py-3 sm:py-4">
                      <Link
                        to={`/user/${user.id}`}
                        className="flex items-center space-x-4 relative"
                      >
                        <div className="flex-shrink-0">
                          <img
                            className="w-10 h-10 rounded-full"
                            src="https://plus.unsplash.com/premium_photo-1725022935609-585e3f42b992?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw2fHx8ZW58MHx8fHx8"
                            alt={`${user?.fullname} image`}
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                            {user?.fullname}
                          </p>
                          <div className="text-sm flex items-center gap-2 text-gray-500 truncate dark:text-gray-400">
                            <div className="gender flex items-center gap-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={`${
                                  user?.gender === 1
                                    ? "fill-blue-500"
                                    : "fill-red-500 rotate-180"
                                } size-3`}
                                viewBox="0 0 351.778 351.778"
                              >
                                <path d="M321.778,95.024l30-0.049l-0.154-94.821L256.802,0l-0.049,30l43.741,0.071l-58.583,58.583  c-26.385-21.595-59.13-33.339-93.68-33.339c-39.594,0-76.817,15.419-104.814,43.417C15.419,126.729,0,163.953,0,203.547  s15.419,76.818,43.416,104.815s65.221,43.416,104.814,43.416s76.818-15.419,104.815-43.416  c54.215-54.215,57.572-140.324,10.073-198.49l58.588-58.588L321.778,95.024z M231.833,287.149  c-22.331,22.331-52.021,34.629-83.603,34.629S86.96,309.48,64.629,287.149C42.298,264.818,30,235.128,30,203.547  s12.298-61.271,34.629-83.602s52.021-34.629,83.602-34.629c31.581,0,61.271,12.298,83.603,34.629  C277.931,166.044,277.931,241.051,231.833,287.149z" />
                              </svg>
                              <div className="age text-gray-400 text-xs">
                                {user?.birthday}
                              </div>
                            </div>
                            <div className="maps flex items-center gap-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                className="stroke-gray-400 size-3"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                                />
                              </svg>
                              <div className="age text-gray-400 text-xs">
                              {user?.distance_km && !isNaN(user.distance_km) 
                              ? user.distance_km.toFixed(2) + ' km' 
                              : 'Gần đây'}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="py-2 px-3">
                Không có người dùng, vui lòng tìm lại để trải nghiệm
              </p>
            )}
          </div>
        </div>
      </div>  
      <div className="flex justify-center my-2">
        <button onClick={updateLocation} className="btn btn-primary">
          Thay đổi vị trí
        </button>
      </div>
    </>
  );
};

export default Home;
