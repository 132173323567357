const InputField = ({ id, label, type = 'text', register, errors, defaultValue, placeholder, required }) => {
    return (
        <div>
            <label htmlFor={id} className="block text-gray-700 mb-2">{label}</label>
            <input
                id={id}
                name={id}
                type={type}
                {...register(id, { required })}
                defaultValue={defaultValue}mb-2
                placeholder={placeholder}
                className="w-full block rounded-lg border border-gray-200 bg-white p-3 text-sm font-normal text-gray-900 outline-none"
            />
            {required && errors[id] && <span className="text-red-600 text-sm">{errors[id]?.message}</span>}
        </div>
    );
};

export default InputField;