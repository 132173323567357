import { nanoid } from 'nanoid';

class WebSocketService {
  constructor(token) {
    this.socket = null;
    this.listeners = [];
    this.token = token;
    this.device_id = nanoid();
  }
  connect() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      console.log('WebSocket is already open.');
      return;   
    }

    this.socket = new WebSocket(
      `wss://location.com/ws/?token=${this.token}&device_id=${this.device_id}`
    );
    this.socket.onopen = () => {
      console.log('WebSocket connection opened');
      this.startHeartbeat();
    }

    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      this.listeners.forEach((listener) => listener(data));
    };

    this.socket.onclose = (event) => {
      console.log('WebSocket connection closed', event);
      this.stopHeartbeat();
    };
    

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
    }
  }

  onMessage(callback) {
    this.listeners.push(callback);
  }

  offMessage(callback) {
    this.listeners = this.listeners.filter((listener) => listener !== callback);
  }

  sendMessage(recipient_id, content, temp_id) {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(
        JSON.stringify({
          action: 'send_message',
          recipient_id,
          content,
          temp_id, 
        })
      );
    } else {
      console.warn('WebSocket is not open. Cannot send message.');
    }
  }

  sendMessageRead(message_id) {
    console.log('Sent message_read for message_id:', message_id);
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(
        JSON.stringify({
          action: 'message_read',
          message_id,
        })
      );
      console.log('Sent message_read for message_id:', message_id);
    } else {
      console.warn('WebSocket is not open. Cannot send message_read.');
    }
  }

  startHeartbeat() {
    this.heartbeatInterval = setInterval(() => {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ action: 'heartbeat' }));
      }
    }, 60000); // Gửi heartbeat mỗi 1 phút
  }

  stopHeartbeat() {
    clearInterval(this.heartbeatInterval);
  }

  loadHistory(recipient_id, limit = 20, before = null) {
    if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(
            JSON.stringify({
                action: 'load_history',
                recipient_id,
                limit,
                before, // Thêm tham số 'before' nếu có
            })
        );
    } else if (this.socket.readyState === WebSocket.CONNECTING) {
        this.socket.addEventListener('open', () => {
            this.socket.send(
                JSON.stringify({
                    action: 'load_history',
                    recipient_id,
                    limit,
                    before, // Thêm tham số 'before' nếu có
                })
            );
        });
    } else {
        console.warn('WebSocket is not open. Cannot load history.');
    }
  }

}

export default WebSocketService;
