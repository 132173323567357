import axios from "axios";

const baseUrl = process.env.REACT_APP_PORT_URL_SERVER
const apiInstance = axios.create({
  baseURL: `${baseUrl}/api/`,
  headers: {
    "Content-Type": "application/json",
  },
  credentials: 'include',
  timeout: 10000,  
});

const ApiService = {
  getUsers: (params) => {
    return axios.get('/api/users', { params });
  },
  
  getUserById: (id) => apiInstance.get(`/user/${id}`),
  
  getUserToken: () => apiInstance.get(`/user/info`),
  
  getChatHistory: (userId, friendId, token) => 
    apiInstance.get(`/chats/${userId}/${friendId}`, {
      headers: { Authorization: `Bearer ${token}` },
    }),
  
  getCurrentUser: (token) => 
    apiInstance.get('/me', {
      headers: { Authorization: `Bearer ${token}` },
    }),

  updateProfileUser: ({id, data}) => apiInstance.put(`/user/profile/${id}`, data),
  
  checkAuth: () => apiInstance.get('auth/check-auth'),

  sendMessage: (senderId, receiverId, content) =>
    apiInstance.post('/messages/send', {
      sender_id: senderId,
      receiver_id: receiverId,
      content: content,
    }),
};

export default ApiService;
