import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import UserSidebar from "./UserSidebar";

const UserLayout = () => {
    const location = useLocation();

    const hideSidebarRoutes = [
        "/chat/user",
    ];

    const shouldHideSidebar = hideSidebarRoutes.some((path) => location.pathname.startsWith(path))

    return (
        <div className="h-full relative flex flex-wrap gap-2 pl-wrap">
                {!shouldHideSidebar && <UserSidebar />}
                <div className={`conv-list-compact pr-2 ${shouldHideSidebar ? "w-full-important" : ""}`}>
                    <Outlet />
                </div>
        </div>
    );
}

export default UserLayout;