import React from "react";

const NotFound = () => {
    return (
        <p>
            TRANG KHÔNG TÌM THẤY
        </p>
    )
}

export default NotFound;