import React, { useState, useEffect } from "react";
import * as Icon from 'react-feather';
import { getCookie } from "../../../../utils/helper";
import ApiService from "../../../../services/ApiService";
import { Link, useNavigate } from "react-router-dom";

const UserSidebar = () => {
    const token = getCookie('fast_access_token');
    const [isOpen, setIsOpen] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserInfo = async () => {
          if(token){
            const userInfo = await ApiService.getUserToken();
            if (userInfo) {
              setCurrentUser(userInfo?.data?.data);
            } else {
              alert('Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập lại.');
            }
        };
        }
        fetchUserInfo();
      }, [token]);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };  

    const handleLogout = () => {
        if (token) {
            window.location.href = '/account/logout/';
        } else {
            console.log('Đăng xuất không thành công');
        }
    }

    const userId = currentUser?.data?.user_id;  

    return (
        <div className={`p-4 shadow-lg sidebar z-20 h-full fixed top-0 flex items-start justify-between flex-col left-0 ${isOpen ? "active" : ""}`}>
            <div>
                <div className={`sidebar-header flex ${isOpen ? "flex-col" : ""} gap-6 items-start`}>
                    <div onClick={toggleSidebar} className="cursor-pointer" style={{ transform: isOpen ? "none" : "scaleX(-1)" }}>
                        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M0 6.27393C0 3.11499 2.61499 0.5 5.77393 0.5H26.2261C29.3851 0.5 32.0001 3.11499 32.0001 6.27393V26.7261C32.0001 29.8851 29.3851 32.5001 26.2261 32.5001H5.77393C2.61499 32.5 0 29.885 0 26.726L0 6.27393ZM5.31222 7.60188C5.31222 6.83351 5.93511 6.21056 6.70354 6.21056H25.2965C26.0649 6.21056 26.6877 6.83345 26.6877 7.60188C26.6877 8.37032 26.0649 8.99321 25.2965 8.99321H6.70354C5.93516 8.99321 5.31222 8.37032 5.31222 7.60188ZM5.31222 25.53C5.31222 24.7615 5.93511 24.1385 6.70354 24.1385H25.2965C26.0649 24.1385 26.6877 24.7615 26.6877 25.53C26.6877 26.2982 26.0649 26.9212 25.2965 26.9212H6.70354C5.93516 26.9212 5.31222 26.2982 5.31222 25.53ZM17.328 12.1861C16.5597 12.1861 15.9367 12.809 15.9367 13.5775C15.9367 14.3458 16.5597 14.9688 17.328 14.9688H25.2964C26.0648 14.9688 26.6877 14.3458 26.6877 13.5775C26.6877 12.8091 26.0648 12.1861 25.2964 12.1861H17.328ZM15.9367 19.5544C15.9367 18.786 16.5597 18.163 17.328 18.163H25.2964C26.0648 18.163 26.6877 18.786 26.6877 19.5544C26.6877 20.3227 26.0648 20.9457 25.2964 20.9457H17.328C16.5597 20.9457 15.9367 20.3227 15.9367 19.5544ZM11.3442 12.6171C11.7966 12.8592 12.079 13.3307 12.079 13.8437V19.156C12.079 19.6691 11.7966 20.1406 11.3442 20.3826C10.8918 20.6248 10.3429 20.5984 9.91596 20.3137L5.93175 17.6575C5.54467 17.3995 5.31222 16.965 5.31222 16.4999C5.31222 16.0347 5.54472 15.6003 5.93175 15.3422L9.91596 12.6861C10.3429 12.4015 10.8918 12.375 11.3442 12.6171Z"
                                fill="#1C64F2" />
                        </svg>
                        </div>
                    <div>
                        <Link to="/" >
                            <svg className="logo-collaps" width="32" height="32" viewBox="0 0 32 32" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_792_6160)">
                            <path
                                d="M14.6214 32.1746C14.6214 32.1746 25.5336 29.389 23.5452 22.7487C21.8187 16.983 14.7197 17.311 10.6767 14.6694C7.75144 12.7583 5.68865 11.0776 6.24038 8.93822C7.09462 5.62334 13.1539 2.27468 18.8168 1.30859C18.8168 1.30859 13.4309 4.34723 18.2643 7.38512C23.0978 10.423 33.3171 16.2233 29.3124 24.0954C25.3069 31.9675 14.6214 32.1746 14.6214 32.1746Z"
                                fill="url(#paint0_linear_792_6160)" />
                            <path
                                d="M20.9186 24.2826C21.1303 25.7426 20.4847 27.2462 19.433 28.2821C18.3821 29.318 16.9679 29.9245 15.5244 30.2285C11.2517 31.1286 6.52859 29.324 3.94484 25.8034C1.3611 22.2829 1.05633 17.2362 3.19719 13.4297C2.87066 15.6359 2.91795 17.9764 3.88929 19.9836C4.86139 21.9909 6.91592 23.58 9.14461 23.5004C12.1082 23.3946 14.6026 20.5294 17.5406 20.9317C19.2274 21.1622 20.6746 22.5981 20.9186 24.2826Z"
                                fill="url(#paint1_linear_792_6160)" />
                            <path
                                d="M20.5373 4.02199C20.2363 3.19853 20.4262 2.23919 21.0996 1.67696C21.1574 1.62891 21.2182 1.58388 21.2827 1.54184C22.5213 0.744647 25.3565 1.29187 26.6912 0C26.6912 0 25.31 4.69533 22.7555 5.17875C21.3953 5.43622 20.7985 4.73436 20.5373 4.02199Z"
                                fill="#0E5ED9" />
                            <path
                                d="M2.00513 19.2969C2.00513 19.2969 4.82983 26.2021 9.38704 26.8927C13.9442 27.5833 10.1692 29.9606 10.1692 29.9606C10.1692 29.9606 3.04703 27.8596 2.00513 19.2969Z"
                                fill="url(#paint2_linear_792_6160)" />
                            <path
                                d="M18.7642 9.90625C18.7642 9.90625 12.8949 9.90625 7.92334 9.90625C7.92334 9.90625 10.2834 14.4109 17.1766 15.4303C27.0304 16.8873 25.3933 26.1331 25.3933 26.1331C25.3933 26.1331 30.2222 18.3301 23.5452 14.1872C17.7216 10.5743 18.7642 9.90625 18.7642 9.90625Z"
                                fill="url(#paint3_linear_792_6160)" />
                            <path
                                d="M20.9584 3.34511C20.9584 3.34511 20.767 1.89485 22.4244 1.96391C24.0819 2.03297 24.9106 1.30859 24.9106 1.30859C24.9106 1.30859 21.2189 5.48597 20.9584 3.34511Z"
                                fill="url(#paint4_linear_792_6160)" />
                            </g>
                            <defs>
                            <linearGradient id="paint0_linear_792_6160" x1="24.631" y1="29.1686" x2="10.3609" y2="4.45201"
                                gradientUnits="userSpaceOnUse">
                                <stop stopColor="#144CA2" />
                                <stop offset="1" stopColor="#0E5ED9" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_792_6160" x1="1.77771" y1="21.9439" x2="20.9583" y2="21.9439"
                                gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFCB05" />
                                <stop offset="1" stopColor="#F37021" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_792_6160" x1="2.00482" y1="24.6289" x2="11.6373" y2="24.6289"
                                gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFD400" />
                                <stop offset="1" stopColor="#FFD400" stopOpacity="0" />
                            </linearGradient>
                            <linearGradient id="paint3_linear_792_6160" x1="17.5022" y1="26.1332" x2="17.5022" y2="9.90588"
                                gradientUnits="userSpaceOnUse">
                                <stop stopColor="#5284CF" />
                                <stop offset="1" stopColor="#0F8FCF" stopOpacity="0" />
                            </linearGradient>
                            <linearGradient id="paint4_linear_792_6160" x1="16.5" y1="6.50024" x2="24.5" y2="1.50024"
                                gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="1" stopColor="#1A56DB" />
                            </linearGradient>
                            <clipPath id="clip0_792_6160">
                                <rect width="32" height="32" fill="white" />
                            </clipPath>
                            </defs>
                        </svg>
                    </Link>
                    </div>
                </div>

                <div className="main-nav mt-8">
                    <ul className="ml-1 flex flex-col">
                        <li className="mb-6">
                            <Link to="/" className="flex h-full flex-row relative justify-start items-center">
                                <Icon.Users className="size-6 mr-3" />
                                <span className="title-sidebar">Gần đây</span>
                            </Link>
                        </li>
                        <li className="mb-6">
                            <a href="#" className="flex h-full flex-row relative justify-start items-center">
                                <Icon.Twitch className="size-6 mr-3" />
                                <span className="title-sidebar">Tin nhắn</span>
                            </a>
                        </li>
                        <li className="mb-6">
                            <Link to="/settings" className="flex h-full flex-row relative justify-start items-center">
                                <Icon.MapPin className="size-6 mr-3" />
                                <span className="title-sidebar">Tìm quanh đây</span>
                            </Link>
                        </li>
                        <li className="mb-6">
                            <Link to="/location" className="flex h-full flex-row relative justify-start items-center">
                                <Icon.Map className="size-6 mr-3" />
                                <span className="title-sidebar">Thay đổi vị trí</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>      
            {token && (
                <div className="flex items-start justify-center flex-col gap-4">
                    <Link to={`profile/${userId}`} className="flex w-full items-center justify-start">
                        <img className={`object-cover  rounded-full avatar_user ${isOpen ? "" : "mr-3"}`} src="https://plus.unsplash.com/premium_photo-1671656349322-41de944d259b?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D" alt="" />
                        <span className="title-sidebar">Thông tin cá nhân</span>
                    </Link>
                    <button onClick={handleLogout} className="flex h-full flex-row relative justify-start items-center">
                        <Icon.X className="size-6 mr-3 ml-1" />
                        <span className="title-sidebar">Đăng xuất</span>
                    </button>
                </div>
            )}
        </div>
    );
}

export default UserSidebar;