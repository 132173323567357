import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { getCookie } from "./utils/helper";
import AuthLayout from "./app/components/Layouts/Auth/AuthLayout";
import UserLayout from "./app/components/Layouts/User/UserLayout";

// page
import Login from "./app/pages/Auth/Login";
import Register from "./app/pages/Auth/Register";
import NotFound from "./app/pages/Error/NotFound";
import Home from "./app/pages/Home/Home";
import Location from "./app/pages/PermissionLocation/PermissionLocation";
import Settings from "./app/pages/Settings/Settings";
import UserDetail from "./app/pages/User/UserDetail";
import UserList from "./app/pages/User/UserList";
import UserProfile from "./app/pages/User/Profile/UserProfile";
import ChatWindow from "./components/ChatWindow/ChatWindow";
function AppRouter() {
  const protectedAdminLayout = (
    // <RequireAuth type="user">
      <AuthLayout />
    // </RequireAuth>
  );

  return (
    <Routes>
      <Route path="/" element={<UserLayout />}>
        <Route index element={<Home />} />
        <Route path="location" element={<Location />} />
        <Route path="settings" element={<Settings />} />
        <Route path="users" element={<UserList />} />
        <Route path="profile/:id" element={<UserProfile />} />
        <Route path="user/:id" element={<UserDetail />} />
        <Route path="chat/user/:id" element={<ChatWindow />} />
      </Route>
      <Route path="/auth" element={protectedAdminLayout}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
