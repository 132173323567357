import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasLocation, addLocation } from '../../../services/indexedDB'; // Đảm bảo đường dẫn đúng

const PermissionLocation = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false); // Đánh dấu đã kiểm tra vị trí

  useEffect(() => {
    const verifyLocation = async () => {
      try {
        const exists = await hasLocation();
        if (exists) {
          // Nếu đã có vị trí, điều hướng đến trang Settings
          navigate('/settings');
        } else {
          // Nếu chưa có vị trí, hiển thị thông báo và nút "Tiếp tục"
          setIsChecked(true);
        }
      } catch (error) {
        console.error('Error checking location:', error);
        setError('Đã xảy ra lỗi khi kiểm tra vị trí.');
        setIsChecked(true); // Dù có lỗi, vẫn hiển thị UI để người dùng có thể thử lại
      }
    };

    verifyLocation();
  }, [navigate]);

  const handleContinue = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            // Lưu vị trí vào IndexedDB
            await addLocation({ latitude, longitude, timestamp: Date.now() });
            setLoading(false);
            navigate('/settings'); // Điều hướng đến trang Settings sau khi lưu vị trí
          } catch (error) {
            console.error('Error saving location:', error);
            setError('Đã xảy ra lỗi khi lưu vị trí. Vui lòng thử lại.');
            setLoading(false);
          }
        },
        (error) => {
          console.error('Error getting location:', error);
          setError('Không thể lấy vị trí hiện tại. Vui lòng thử lại hoặc kiểm tra cài đặt trình duyệt.');
          setLoading(false);
        }
      );
    } else {
      setError('Trình duyệt của bạn không hỗ trợ Geolocation.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex justify-center flex-col mt-6 items-center relative">
        {isChecked && (
          <>
            <div className="notice-box p-4 bg-blue-50 rounded-lg shadow-lg mb-4">
              <img
                src="https://location.com/assets/clients/images/huongdan.jpg"
                alt="Hướng dẫn"
                className="w-full h-auto outline-none"
              />
              <p className="text-gray-700 text-sm mt-2">
                Làm theo hướng dẫn bên trên để hoàn thành các bước.
              </p>
            </div>
            {error && <p className="text-red-500 mt-4">{error}</p>}
            <button
              onClick={handleContinue}
              type="button"
              className="btn btn-primary mt-80"
              disabled={loading}
            >
              {loading ? 'Đang kiểm tra...' : 'Tiếp tục'}
            </button>
          </>
        )}
        {!isChecked && !error && (
          <div className="loader">
            <p>Đang kiểm tra vị trí...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PermissionLocation;
