import React, { createContext, useEffect, useRef } from 'react';
import WebSocketService from './services/WebSocketService';
import { getCookie } from './utils/helper';
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import AppRouter from "./AppRouter";
import { WebSocketProvider } from './WebSocketContext';
function App() {

  const helmetContext = {};

  return (    
    <WebSocketProvider>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter >
          <AppRouter />
        </BrowserRouter>
      </HelmetProvider>
    </WebSocketProvider>
  );
}

export default App;
