// src/WebSocketContext.js
import React, { createContext, useEffect, useState } from 'react';
import WebSocketService from './services/WebSocketService';
import { getCookie } from './utils/helper';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [webSocketService, setWebSocketService] = useState(null);

  useEffect(() => {
    const token = getCookie("fast_access_token");
    console.log('WebSocketProvider: token:', token);
    if (token && !webSocketService) {
      const wsService = new WebSocketService(token);
      wsService.connect();
      setWebSocketService(wsService);
      console.log('WebSocketProvider: WebSocketService created and connect called');
    } else {
      console.log('WebSocketProvider: No token or WebSocketService already exists');
    }

    return () => {
      if (webSocketService) {
        webSocketService.disconnect();
        console.log('WebSocketProvider: WebSocketService disconnected');
      }
    };
  }, [webSocketService]);

  return (
    <WebSocketContext.Provider value={webSocketService}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketContext;
